'use strict'

class Sticky {

	constructor(element) {

		this._element = element;
		this._clone = element.cloneNode(true);

		let parent = element.parentNode;
		parent.appendChild(this._clone);

		// hack for flex parent.
		setTimeout(() => { this.sync(); }, 0);

		this.toggle();

		window.addEventListener('scroll', (event) => {

			this.toggle();

		});

		window.addEventListener('resize', (event) => {

			this.sync();

		});

	}

	sync() {

		let original = this._element;
		let clone = this._clone;
		let bcr = original.getBoundingClientRect();

		clone.style.left = `${bcr.left}px`;
		clone.style.width = `${bcr.right - bcr.left}px`;
		clone.style.height = `${bcr.bottom - bcr.top}px`;

	}

	toggle() {

		let original = this._element;
		let clone = this._clone;
		let bcr = original.getBoundingClientRect();

		if(bcr.top <= 0) {

			original.classList.add('invisible');
			clone.classList.add('hold');
			clone.classList.remove('hidden');

		}

		else {

			original.classList.remove('invisible');
			clone.classList.add('hidden');
			clone.classList.remove('hold');

		}

	}

}
